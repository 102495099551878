import React, { useContext, useState } from "react";
import DommusToggle from "../../../../components/DommusToggle";
import MenuDetalhes from "../MenuDetalhes";
import { Accordion, AccordionContext, Button, Card, Col, Form, FormControl, InputGroup, Nav, Tab, useAccordionToggle } from "react-bootstrap";
import { ChamadosVinculados } from "../DadosDetalhes/ChamadosVinculados";
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { apenasNumeros } from "../../../../components/InputTextMask";
import { ModalDadosVinculacao } from "../ModalDadosVinculacao";
import Swal from "sweetalert2";


export function DetalhesChamados({ tipoDetalhes, titleToggle, chamados, idAtendimento, setChamados, handleVincularChamado }){
  const [openInputVincularChamado, setOpenInputVincularChamado] = useState(false);
  const {register, handleSubmit, setValue} = useForm();

  const handleOnSubmit = (data)=>{
    if(data){
      let chamado = data.chamado_id ?? "";
      setValue('chamado_id', '');
      setOpenInputVincularChamado(false);
      handleVincularChamado(chamado, true);
    }else{
      Swal.fire({
        titleText: "Ooops ...",
        text: "Nenhum código informado!",
        icon: 'error'
      });
    }
  }

  return (
    <>
      <DommusToggle
        title={
          <MenuDetalhes
            tipoDetalhes={tipoDetalhes}
            titleToggle={titleToggle}
            openInputVincularChamado={()=>{
                setOpenInputVincularChamado((current) => !current);
              }
            }
          />
        }
        toggle={true}
        bodyClass="dommus-toggle-height-fixed-d"
      >
        <div id='dadosDetalhes'>
          {openInputVincularChamado && 
            <form onSubmit={handleSubmit(handleOnSubmit)} style={{margin: '5px 20px'}}>
               <InputGroup.Append>
                  <Form.Control
                    key="IDProtocolo" 
                    type="text" 
                    name="chamado_id"
                    {...register('chamado_id')}
                    onChange={(event) => {
                      if(event.target.value){
                         let temp = apenasNumeros(event.target.value);
                         setValue('chamado_id', temp)
                      }
                    }}
                    placeholder="Nº do Protocolo"
                    style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                  />
                  <Button
                    type='submit'
                    className="btnBuscar"
                    data-toggle="Procurar"
                    variant="warning"
                    style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                  >
                    <FontAwesomeIcon icon={faLink}/> 
                  </Button>
               </InputGroup.Append>
            </form>
          }
          <Card.Body style={{display: "flex", flexDirection: "column", gap: "10px"}}>
              {
                <div className="card-chamado-externo">
                  <ChamadosVinculados idAtendimento={idAtendimento} chamados={chamados} setChamados={setChamados}/>
                </div>
              }
          </Card.Body>
        </div>
      </DommusToggle>
    </>
  )
}
