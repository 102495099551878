import React, {useContext, useState} from 'react';
import DommusToggle from "../../../../components/DommusToggle";
import {Form, OverlayTrigger, Button, Tooltip, FormControl, ListGroup, InputGroup, Row, Col, Container, ModalFooter} from "react-bootstrap";
import './index.scss';
import caAPI from "../../../../services/caAPI";
import {trackPromise} from "react-promise-tracker";
import formatDommus from "../../../../helpers/format";
import Swal from "sweetalert2";
import {cpfMask, celularMask} from '../../../../components/InputTextMask';
import DommusLoadingArea from '../../../../components/DommusLoading/DommusLoadingArea';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPaperPlane, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalDommus from "../../../../components/Modal/";
import { DommusAlert } from '../../../../components/DommusAlert';
import { EmpreendimentoContext } from '../../../../contexts/EmpreendimentosContext';
import { CampanhaContext } from '../../../../contexts/CampanhasContext';

export default function BuscarProcesso({
                                           atendimento,
                                           listaProcessos,
                                           buscarProcessos,
                                           handleSelecionarDado,
                                            loading
                                           
                                       }) {

    const [idProcesso, setIdProcesso] = useState();
    const [iDProtocolo, setIdProtocolo] = useState();
    const [idOpv, setIdOpv] = useState();
    const [infoBuscar, setInfoBuscar] = useState();
    const [tipoBusca, setTipoBusca] = useState(0);
    const [disableBtnLead, setDisableBtnLead] = useState(false);
    const [campanha, setCampanha] = useState(null);
    const [empreendimento, setEmpreendimento] = useState(null);
    const [openModalLeads, setOpenModalLeads] = useState(false);  
    const listaTipoBusca = ['Dados do Cliente', 'Nº da OPV', 'Nº do Processo', 'Nº Chamado/Ticket']
    const {empreendimentoLista} = useContext(EmpreendimentoContext);
    const {campanhaLista} = useContext(CampanhaContext);

    function mostrarTipoBusca() {
        switch (tipoBusca) {
            case 0:
                return <>
                    <FormControl key="DadosCliente" type="text" onChange={e => setInfoBuscar(e.target.value)}
                                 placeholder="Nome/Tel/Email/CPF"/>
                </>;
            case 1:
                return <>
                    <FormControl key="OPV" type="text" onChange={e => setIdOpv(e.target.value)}
                                 placeholder="Nº da OPV"/>
                </>;
            case 2:
                return <>
                    <FormControl key="IDProcessos" type="text" onChange={e => setIdProcesso(e.target.value)}
                                 placeholder="Nº do Processo"/>
                </>;
            case 3:
                return <>
                    <FormControl key="IDProtocolo" type="text" onChange={e => setIdProtocolo(e.target.value)}
                                 placeholder="Nº do Protocolo"/>
                </>;
            default:
                break;
        }
        return
    }

    function handleBuscar(event) {
        event.preventDefault();

        switch (tipoBusca) {
            case 0:
                if (infoBuscar.length < 5) {
                    Swal.fire({
                        titleText: "Atenção",
                        text: "Digite no mínimo 5 caracteres para buscar",
                        icon: 'info'
                    });
                    return;
                }
                buscarProcessos(infoBuscar, 0);
                break;
            case 1:
                buscarProcessos(idOpv, 1);
                break;
            case 2:
                buscarProcessos(Number(Number(idProcesso) === 0 ? null : idProcesso), 2);
                break;
            case 3:
                buscarProcessos(Number(iDProtocolo), 3);
                break;
            default:
                break;
        }
    }

    function handleCriarOportunidade() {
        setDisableBtnLead(true)
        if (empreendimento != null || campanha != null) {
            const indexCampanha = campanhaLista.findIndex((item) => Number(item.id_campanha) === Number(campanha));
            const indexEmpreendimento = empreendimentoLista.findIndex((item) => Number(item.id) === Number(empreendimento));
            let mensagem = "";
            if((empreendimento != null && empreendimento != "null") && (campanha == null || campanha == "null")){
                mensagem = `Você selecionou o empreendimento: ${empreendimentoLista[indexEmpreendimento].nome}`;
            }
            if((empreendimento == null || empreendimento == "null") && (campanha != null && campanha != "null")){
                mensagem = `Você selecionou a campanha: ${campanhaLista[indexCampanha].nome}`;
            }
            if((empreendimento != null && empreendimento != "null") && (campanha != null && campanha != "null")) {
                mensagem = `Você selecionou o empreendimento: ${empreendimentoLista[indexEmpreendimento].nome} e a campanha: ${campanhaLista[indexCampanha].nome}`;
            }
            Swal.fire({
                titleText: "Você confirma que deseja enviar para o módulo Dommus Prospecta?",
                text: mensagem,
                icon: "question",
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Sim, enviar!",
                cancelButtonText: "Não, cancelar.",
                customClass: {
                    confirmButton: "btn btn-primary mr-3",
                    cancelButton: "btn btn-danger",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    submitLead()
                    return;
                }else{
                    setDisableBtnLead(false)
                }
            });
        }else{
            submitLead()
        }
        return;
    }

    function submitLead(){
        const data = {
            atendente: atendimento.atendente
        }
        trackPromise(
            caAPI.atendimento.lead.enviaLead(atendimento.id, campanha, empreendimento, data).then(res => {
                setOpenModalLeads(false)
                Swal.fire({
                    titleText: "Lead criado com sucesso",
                    text: "Enviado para distribuição",
                    icon: "success",
                });
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar o Lead " + e,
                    icon: 'error'
                });
            })
        )
    }

    return (
        <>
            <DommusToggle title='Detalhes da OPV/Processo' toggle={true} bodyClass="dommus-toggle-height-fixed-busca-processo position-relative">
                <div id='buscaProcessos'>
                    <div className="headerBusca">
                        <Form onSubmit={handleBuscar}>
                                <Form.Label>Buscar por {listaTipoBusca[tipoBusca]}:</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            key="buttonAlterarParaEmail"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip-editar`}>
                                                    {listaTipoBusca[(tipoBusca === (listaTipoBusca.length - 1)) ? 0 : (tipoBusca + 1)]}
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                className="btnAlterar"
                                                style={{ zIndex: 0 }}
                                                onClick={()=>{
                                                    setTipoBusca((tipoBusca === (listaTipoBusca.length - 1)) ? 0 : (tipoBusca + 1));
                                                }}
                                            >
                                                <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>
                                    {mostrarTipoBusca(tipoBusca)}
                                    <InputGroup.Append>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                tipoBusca === 0 ?
                                                <Tooltip id={`tooltip-editar`}>Procurar</Tooltip> :
                                                <Tooltip id={`tooltip-editar`}>Vincular</Tooltip>
                                            }
                                        >
                                            <Button
                                                type='submit'
                                                className="btnBuscar"
                                                data-toggle="Procurar"
                                                style={{ zIndex: 0 }}
                                                
                                            >
                                                {
                                                    tipoBusca === 0 ?
                                                    <FontAwesomeIcon icon={faSearch}/> :
                                                    <FontAwesomeIcon icon={faLink}/> 
                                                }
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Append>
                                </InputGroup>
                        </Form>
                    </div>
                    <div className="conteudoListado">
                        <DommusLoadingArea area="buscarProcessoArea" fundo={"#fff"}/>
                        <ListGroup className="listaProcessosEncontrados">
                            {listaProcessos?.map((processo, index) => {
                                return <div key={index}>
                                    <OverlayTrigger
                                        key={`resultadoProponente ${index}`}
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-${processo.processo_id || processo.id_oportunidade}`}>
                                                {processo.proponente_encontrado_nome || processo.envolvido_nome}
                                            </Tooltip>
                                        }
                                    >
                                        <ListGroup.Item action className="processoListItem" key={index}
                                            onClick={() => handleSelecionarDado(index)}
                                        >
                                            <div className="proponenteNome">
                                                {formatDommus.zeroPad(processo.processo_id || processo.id_oportunidade, 5)} {(processo.proponente_encontrado_nome || processo.envolvido_nome) ? ` - ${(processo.proponente_encontrado_nome || processo.envolvido_nome)}` : ""}
                                            </div>
                                            {
                                              processo.proponente_encontrado_cpf && <div className="proponenteCPFTelefone">
                                                  {processo.proponente_encontrado_cpf && cpfMask(processo.proponente_encontrado_cpf)} {processo.telefone ? ` - ${celularMask(processo.telefone)}` : ""}
                                              </div>
                                            }
                                            {
                                              processo?.email && <div className="proponenteEmail">
                                                  {processo.email}
                                              </div>
                                            }

                                        </ListGroup.Item>
                                    </OverlayTrigger>
                                </div>
                            })}


                            { !loading &&
                            
                                <DommusAlert title={'Atenção!'} message={"Nenhum registro encontrado para ser vinculado a esse contato!"}/>
                            
                            }
                        </ListGroup>
                        <div className="totalEncontrado">
                            Encontrados: {listaProcessos.length} registros
                        </div>
                    </div>
                </div>

            </DommusToggle>
            <div>
                <div className="footerBtnEnviar">
                    <Button disabled={disableBtnLead} className="btnEnviarGestaoLeads"
                            onClick={()=>{setOpenModalLeads(true)}}
                            >
                      Enviar para gestão de leads
                    </Button>
                </div>
            </div>
            <ModalDommus open={openModalLeads}
                titulo={"Enviar para Gestão de Leads"}
                size={"md"}
                close={() => { setOpenModalLeads(false)}}
                content={
                    <>
                        <select className="form-control" onChange={(e) => setEmpreendimento(e.target.value)}>
                            <option value="null">Selecione o Empreendimento</option>
                            {empreendimentoLista.filter(empreendimento => Number(empreendimento.ativo)).map((empreendimento, index) => {
                                return <option key={index} value={empreendimento.id}>{empreendimento.nome}</option>
                            }
                            )}
                        </select>
                        <select className="form-control mt-2" onChange={(e) => setCampanha(e.target.value)}>
                            <option value="null">Selecione a Campanha</option>
                            {campanhaLista.map((campanha, index) => {
                                return <option key={index} value={campanha.id_campanha}>{campanha.nome}</option>
                            })
                            }
                        </select>
                        <ModalFooter className='formFooter'>
                            <Button
                                variant={"success"}
                                onClick={handleCriarOportunidade}                                
                            >
                                <FontAwesomeIcon
                                    icon={faPaperPlane}
                                    style={{ marginRight: "15px" }}
                                />
                                Sim, Enviar
                            </Button>
                            <Button
                                onClick={() => setOpenModalLeads(false)}
                                variant={"secondary"}
                                type={"button"}
                                >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{ marginRight: "15px" }}
                                />
                                Cancelar
                            </Button>
                        </ModalFooter>
                    </>
                }
            />
        </>
    )
}
