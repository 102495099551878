import api from "./api";
import caAPI from "./caAPI";

export const DashboardService = {
  buscarDadosPorUsuario: (idUsuario) => {
    return api.get(`perfil-usuario/usuario/dashboard/${idUsuario}`, { params: caAPI.getLocalState('filtrosDashboard') })
  },
  buscarDadosUsuarios: () => {
		return api.get(`/perfil-usuario/usuario/dashboard`, { params: caAPI.getLocalState('filtrosDashboard') })
	},

  buscarListaUsuarios: () => {
		return api.get(`/perfil-usuario/usuario/dashboard/metricas`, { params: caAPI.getLocalState('filtrosDashboard') })
	},

  buscarMetricasUsuario: (idUsuario) => {
		return api.get(`/perfil-usuario/usuario/dashboard/metricas/${idUsuario}`, { params: caAPI.getLocalState('filtrosDashboard') })
	},
};