import axios from 'axios';
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}
  
const api = axios.create({
    baseURL: process.env.REACT_APP_CA_API,
    headers: {
        'Authorization': JSON.parse(localStorage.getItem('@DommusCA-token')),
        'X-Gu-Access-Token': getCookie('gu_access_token')
    }
});

export default api;
