import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Card, CardGroup, Col } from 'react-bootstrap';
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import caAPI from "../../../services/caAPI";
import CommonService from "../../../services/CommonService";
import AtendimentoService from "../../../services/AtendimentoService";
import Swal from "sweetalert2";
import MidiaSocialIcone from "../../../components/MidiaSocialIcone";
import "./style.scss";
import { trackPromise } from "react-promise-tracker";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import { AtendentesContext } from "../../../contexts/AtendentesContext";
import { VoipContext } from "../../../contexts/VoipContext";
import { celularMask } from "../../../components/InputTextMask";
import useTelefoneInternacionalInput from '../../../hooks/useTelefoneInternacionalInput';
import CreatableSelect from "react-select/creatable";
import { successToast } from '../../../components/DommusToast';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';
import {isMobile} from 'react-device-detect';

export default function ModalNovoAtendimento({ posicaoListaAtentimentos, setOpenModalNovoAtendimento, idAtendimento }) {

    const [atendimentoEditar, setAtendimentoEditar] = useState();
    const [meioComunicacaoInput, setMeioComunicacaoInput] = useState();
    const [situacaoInput, setSituacaoInput] = useState();
    const [atendenteInput, setAtendenteInput] = useState();
    const [tipoAtendimentoInput, setTipoAtendimentoInput] = useState();
    const [nomeDestinatarioInput, setNomeDestinatarioInput] = useState();
    const [contatoDestinatarioInput, setContatoDestinatarioInput] = useState("");
    const [contatoOrigemInput, setContatoOrigemInput] = useState();
    const [ramalInput, setRamalInput] = useState();
    const [listaMeioComunicacao, setListaMeioComunicacao] = useState();
    const [listaSituacao, setListaSituacao] = useState();
    const [listaTipoAtendimento, setListaTipoAtendimento] = useState();
    const [maskDestinatarioType, setMaskDestinatarioType] = useState();
    const [textButton, setTextButton] = useState('Iniciar Atendimento');
    const [listaContatoOrigem, setListaContatoOrigem] = useState([]);
    const [refInputTelefone, objetoInputTelefone, getNumeroInternacional] = useTelefoneInternacionalInput(atendimentoEditar?.contato_destino);
    const { handleAdiconaLista,  listaAtendimentos,  handleReloadAtendimento, setListaAtendimentos, setAtendimentoAtivo } = useContext(AtendimentoContext);
    const {listaAtendentes, recarregarListaAtendentes, resolverNomeAtendente, testarAtendenteGerenciado, testarUsuarioAtendeInputMensagem} = useContext(AtendentesContext);
    const [enviando, setEnviando] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [listaTags, setListaTags] = useState([]);
    const {inCallStatus} = useContext(VoipContext);
    //aplica mascara do contato destino quando for número
    useEffect(() => {
      if (atendimentoEditar) {
          setTextButton('Salvar Alterações');
          if(maskDestinatarioType === 'phone'){
            setContatoDestinatarioInput(celularMask(atendimentoEditar.contato_destino, true));
          }else{
            setContatoDestinatarioInput(atendimentoEditar.contato_destino);
          }
          setMeioComunicacaoInput(atendimentoEditar.meio_comunicacao.slug);
          return;
      }
      if (maskDestinatarioType === 'phone') {
          setContatoDestinatarioInput(val => celularMask(val, true));
      }
    }, [contatoDestinatarioInput, maskDestinatarioType, atendimentoEditar])

    useEffect(() => {
        trackPromise(
            caAPI.meio_comunicacao.list().then(res => {
                setListaMeioComunicacao(res.data);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os meios de comunicação  " + err,
                    icon: 'error'
                });
            }),

            caAPI.situacao.list().then(res => {
                setListaSituacao(res.data);
            }).catch(err => Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar a lista de situações  " + err,
                icon: 'error'
            })),

            caAPI.tipo_atendimento.list().then(res => {
                setListaTipoAtendimento(res.data);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os tipos de atendimento  " + err,
                    icon: 'error'
                });
            }),

            caAPI.tags.list().then(res => {
            let formatado = res.data.data.map((value)=>{
                return{
                    value: value.id_tag,
                    label: value.nome,
                }
            });


            setListaTags(formatado);
            }).catch(err => {
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar as tags  " + err,
                icon: 'error'
            });
            }),
        )
    }, [])

    //Busca atendimento via IdAtendimento
    useEffect(() => {
        if (idAtendimento) {
            trackPromise(
                caAPI.atendimento.find(idAtendimento).then(res => {
                    setAtendimentoEditar(res.data);
                    setNomeDestinatarioInput(res.data.nome);
                    setContatoDestinatarioInput(celularMask(res.data.contato_destino, true));
                    setContatoOrigemInput(res.data.contato_origem);
                    setRamalInput(res.data.ramal);
                    setTipoAtendimentoInput(res.data.tipo_atendimento.id);
                    setSituacaoInput(res.data.situacao);
                    setSelectedTags(res.data.tags.map((tag)=>{
                        return {
                            value: tag.id_tag,
                            label: tag.nome,
                        }
                    }));
                }).catch(err => {
                    Swal.fire({
                        titleText: "Erro",
                        text: "Houve um problema ao buscar o Atendimento",
                        icon: 'error'
                    });
                })
            )
        }
    }, [idAtendimento]);

    const removerTag = (tags)=>{
        if(idAtendimento){
            let formatValues = {
                tags: tags.map((tag)=>{return {id_atendimento: idAtendimento, id_tag: tag.value, nome: tag.label}})
            };
            caAPI.tags.removerTagsAtendimento(formatValues).then(
                (response)=>{
                    successToast.fire({text: "Tag removida com sucesso!"});
                }
            )
            .catch((error) => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Erro ao remover tag: " + error,
                    icon: 'error'
                })
            });
        }
    }

    const definirContatoOrigem = (opcoesSelecionadas) => {
        let novoValor = null;
        let novoRamal = null;
        if(opcoesSelecionadas && opcoesSelecionadas.length){
            novoValor = opcoesSelecionadas[0].value;
            novoRamal = opcoesSelecionadas[0].dataset.ramal;
        }
        setContatoOrigemInput(novoValor);
        setRamalInput(novoRamal);
    }

    function atendimentoExiste(atendimento) {
        Swal.fire({
            titleText: "Já existe um atendimento aberto com esse contato para  " + atendimento.nome,
            text: "Deseja abrir a janela deste atendimento?",
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                    handleAdiconaLista(atendimento);
                    setAtendimentoAtivo(atendimento);
            }
        })
    };


    const handleTagsSelecao = (values)=>{
        if(values.length > selectedTags.length){
            setSelectedTags(values);
        }else{
            let removido = selectedTags.filter((tag)=>{return !values.includes(tag);});
            setSelectedTags(values);
            removerTag(removido);
        }
    }

    function buscarListaContatosOrigem(slug) {
        trackPromise(caAPI.meio_comunicacao.buscarContatoOrigem(slug)).then(
            (res) => {
                setListaContatoOrigem(res.data);
            }
        );
    }

   //Define tipo de contato destino baseado no meio de comunicação
    useEffect(() => {
        if (meioComunicacaoInput === 'email') {
            setMaskDestinatarioType('email');
        } else {
            if (meioComunicacaoInput === 'voip' || meioComunicacaoInput === 'whatsapp') {
                setMaskDestinatarioType('phone');
            }
        }
    }, [meioComunicacaoInput]);

    //Submit do formulário de novo atendimento
    const handleSubmitForm = function () {
        let contatoDestino = contatoDestinatarioInput;
        if (objetoInputTelefone) {
          contatoDestino = getNumeroInternacional();
        }else if (maskDestinatarioType === 'email') {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(contatoDestinatarioInput).toLowerCase())) {
                Swal.fire({
                    titleText: "Atenção",
                    text: "Digite um email válido",
                    icon: 'info'
                });
                return;
            }
        }else if (maskDestinatarioType === 'phone'){
          if (contatoDestinatarioInput) {
                contatoDestino = CommonService.formatarDeMascaraPhone(contatoDestinatarioInput);
            }
        }
        const meiosDeComunicacaoContatoOrigemObrigatorio = [
            'voip', 
            'whatsapp', 
            'email'
        ];
        if (!situacaoInput ||
            // !atendenteInput ||
            !tipoAtendimentoInput ||
            !meioComunicacaoInput ||
            !contatoDestinatarioInput ||
            (meiosDeComunicacaoContatoOrigemObrigatorio.includes(meioComunicacaoInput) && !contatoOrigemInput)
        ) {
            Swal.fire({
                titleText: "Atenção",
                text: "Preecha todos os campos obrigatórios",
                icon: 'info'
            });
            return;
        }
        const novoEnviando = true;
        setEnviando(novoEnviando);
        var data = {
            situacao: situacaoInput,
            atendente: atendenteInput,
            tipo_atendimento: tipoAtendimentoInput,
            meio_comunicacao: meioComunicacaoInput,
            contato_origem: contatoOrigemInput,
            contato_destino: contatoDestino,
            ramal: ramalInput,
            nome: nomeDestinatarioInput,
            tags: selectedTags.map((tag)=>{
                return{
                    id_tag: tag.__isNew__ ? null : tag.value,
                    nome: tag.label,
                }
            })
        }
       
        if (atendimentoEditar) {
            trackPromise(caAPI.atendimento.update(data, idAtendimento).then(res => {
              const novaListaAtendimentos = listaAtendimentos.slice();
              let novoAtendimento = res.data;
              if(
                testarUsuarioAtendeInputMensagem(novoAtendimento) ||
                (
                  novoAtendimento.origem &&
                  novoAtendimento.origem.id &&
                  AtendimentoService.testarUsuarioAtualVeOrigem(novoAtendimento.origem.id)
                )
              ){
                novoAtendimento.nome_atendente = resolverNomeAtendente(novoAtendimento.atendente);
                novaListaAtendimentos.splice(posicaoListaAtentimentos, 1, novoAtendimento);
                handleReloadAtendimento()
              }else{
                novaListaAtendimentos.splice(posicaoListaAtentimentos, 1);
                setAtendimentoAtivo(null);
              }
              setListaAtendimentos(novaListaAtendimentos);
              setOpenModalNovoAtendimento(false);
              const novoEnviando = false;
              setEnviando(novoEnviando);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Erro ao editar atendimento",
                    icon: 'error'
                });
                const novoEnviando = false;
                setEnviando(novoEnviando);
            }))
        }
        else {
            trackPromise(caAPI.atendimento.create(data).then(res => {
                handleAdiconaLista(res.data)
                if(res.data?.mensagem == "existe") {
                 setOpenModalNovoAtendimento(false);
                 atendimentoExiste(res.data.conteudo)
                }else{
                    setOpenModalNovoAtendimento(false);
                    const novoEnviando = false;
                    setEnviando(novoEnviando);
                }
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Erro ao criar/editar atendimento",
                    icon: 'error'
                });
                const novoEnviando = false;
                setEnviando(novoEnviando);
            }))
        }
    };

    return <div className="novoAtendimento">
    <Form>

        <Row>
            <Col xl={3}>
                    <Form.Group >
                        <Form.Label >
                            Meio de Comunicação: <DommusRequiredSpan />
                        </Form.Label>
                        <Form.Control
                            as="radio"
                            custom
                            onChange={(event) => {
                                setMeioComunicacaoInput(event.target.id);
                                buscarListaContatosOrigem(event.target.id);
                            }}
                        >
                            {!isMobile && <CardGroup>
                                {listaMeioComunicacao && !atendimentoEditar && listaMeioComunicacao.map((midia) => {
                                    return (
                                        midia.criavel === 1 && (
                                            <div key={midia.id}>
                                                <Card className="midiaCard">
                                                    <Card.Body className="midiaCorpo">
                                                        <Form.Check
                                                            className="midia"
                                                            type="radio"
                                                            id={midia.slug}
                                                            value={midia.slug}
                                                        >
                                                            <Form.Check.Input type='radio' name="selecionaMidia" isValid />
                                                            <Form.Check.Label >
                                                                <MidiaSocialIcone midia={midia.slug} />
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )
                                    )
                                }
                                )}

                                {!!atendimentoEditar &&
                                    <Card className="midiaCard">
                                        <Card.Body className="midiaCorpo">
                                            <Form.Check
                                                className="midia"
                                                type="radio"
                                                id={atendimentoEditar.meio_comunicacao.slug}
                                                value={atendimentoEditar.meio_comunicacao.slug}
                                            >
                                                <Form.Check.Input checked={true} type='radio' name="selecionaMidia" isValid />
                                                <Form.Check.Label >
                                                    <MidiaSocialIcone midia={atendimentoEditar.meio_comunicacao.slug} />
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Card.Body>
                                    </Card>
                                }
                            </CardGroup>}
                            {
                              isMobile && listaMeioComunicacao && listaMeioComunicacao.length && (
                                <Row xs={3} md={2}
                                  className={`gs-${((listaMeioComunicacao.length % 2 == 0) ? listaMeioComunicacao.length : (listaMeioComunicacao.length + 1))}`}>
                                { !atendimentoEditar && listaMeioComunicacao  && listaMeioComunicacao.map((midia) => {
                                    return <Col key={midia.id}>
                                              <Card className="midiaCard">
                                                  <Card.Body className="midiaCorpo">
                                                      <Form.Check
                                                          className="midia"
                                                          type="radio"
                                                          id={midia.slug}
                                                          value={midia.slug}
                                                      >
                                                          <Form.Check.Input type='radio' name="selecionaMidia" isValid />
                                                          <Form.Check.Label >
                                                              <MidiaSocialIcone midia={midia.slug} />
                                                          </Form.Check.Label>
                                                      </Form.Check>
                                                  </Card.Body>
                                              </Card>
                                            </Col>;
                                  })
                                }
                                { atendimentoEditar &&
                                  <Col>
                                    <Card className="midiaCard">
                                        <Card.Body className="midiaCorpo">
                                            <Form.Check
                                                className="midia"
                                                type="radio"
                                                id={atendimentoEditar.meio_comunicacao.slug}
                                                value={atendimentoEditar.meio_comunicacao.slug}
                                            >
                                                <Form.Check.Input checked={true} type='radio' name="selecionaMidia" isValid />
                                                <Form.Check.Label >
                                                    <MidiaSocialIcone midia={atendimentoEditar.meio_comunicacao.slug} />
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Card.Body>
                                    </Card>
                                  </Col>
                                }
                                </Row>
                              )
                            }
                        </Form.Control>
                    </Form.Group>
                    { atendimentoEditar &&
                    <div className='contato-origem-section'>
                        <span className='label'>Originado por:</span>
                        <div className='text'>
                            <BsFillTelephoneOutboundFill/>
                            {atendimentoEditar.meio_comunicacao?.contatos_origem?.find((contato)=>contato.valor === atendimentoEditar.contato_origem)?.descricao || "---"}
                        </div>
                    </div>
                    }
            </Col>
            <Col>
                <Row xs={1} md={2}>
                    <Col>
                        <Form.Group as={Col}  controlId="formDestinatario">
                            <Form.Label>Nome{(!isMobile && " Destinatário") || ""}: <DommusRequiredSpan /></Form.Label>
                            <Form.Control type="text" value={nomeDestinatarioInput}
                                onChange={(event) => setNomeDestinatarioInput(event.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group as={Col} controlId="formContatoDestinatario">
                            <Form.Label>Contato{(!isMobile && " Destinatário") || ""}: <DommusRequiredSpan /></Form.Label>
                            <Form.Control type="text" value={contatoDestinatarioInput} disabled={!!atendimentoEditar} ref={(meioComunicacaoInput == 'whatsapp' && refInputTelefone) || null}
                                onChange={(event) => setContatoDestinatarioInput(meioComunicacaoInput == 'whatsapp' ? getNumeroInternacional() : event.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={1} md={2}>
                    <Col >
                        <Form.Group as={Col} controlId="formSituacao">
                            <Form.Label>Status{(!isMobile && " do Atendimento") || ""}: <DommusRequiredSpan /></Form.Label>
                            <Form.Control as="select" custom onChange={(event) => setSituacaoInput(event.target.value)}>
                                <option hidden>Selecione o status:</option>
                                {listaSituacao && listaSituacao.map((situacao) => {
                                    const selected = atendimentoEditar && atendimentoEditar.situacao === situacao.id;
                                    return <option key={situacao.id} selected={selected} value={situacao.id}>{situacao.nome}</option>
                                }
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col >
                        <Form.Group as={Col} controlId="formAtendimento">
                            <Form.Label>Tipo{(!isMobile && " do Atendimento") || ""}: <DommusRequiredSpan /></Form.Label>
                            <Form.Control as="select" custom onChange={(event) => setTipoAtendimentoInput(event.target.value)}>
                                <option hidden>Selecione o tipo do atendimento:</option>
                                {listaTipoAtendimento && listaTipoAtendimento.map((tipo) => {
                                    const selected = atendimentoEditar && atendimentoEditar.tipo_atendimento.id === tipo.id;
                                    return <option key={tipo.id} selected={selected}
                                        value={tipo.id}>{tipo.descricao}</option>;
                                }
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={1} md={atendimentoEditar ? 3 : 2}>
                    <Col
                        style={listaContatoOrigem && listaContatoOrigem.length > 0 ? {} : { display: "none" }}
                    >
                        <Form.Group as={Col} controlId="formNome">
                            <Form.Label>Originado por: <DommusRequiredSpan /></Form.Label>
                            <Form.Control
                                disabled={((listaContatoOrigem && !listaContatoOrigem.length > 0) || !!atendimentoEditar) ?? "disabled"}
                                as="select" custom onChange={(event) => definirContatoOrigem(event.target.selectedOptions)}>
                                <option hidden>Selecione o contato para origem</option>
                                {listaContatoOrigem && listaContatoOrigem.map((numero, index) => {
                                    const selected = contatoOrigemInput && contatoOrigemInput == numero.valor && (ramalInput || ramalInput === 0) && ramalInput == numero.ramal;
                                    return <option key={index} selected={selected} data-ramal={numero.ramal} value={numero.valor}>{numero.descricao ?? numero.valor}</option>;
                                })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {atendimentoEditar ?
                        <Col>
                            <Form.Group as={Col} controlId="formAtendente">
                                <Form.Label>Atendente: <DommusRequiredSpan /></Form.Label>
                                <Form.Control as="select" disabled={inCallStatus && idAtendimento} custom onChange={(event) => setAtendenteInput(event.target.value)}>
                                    <option>Selecione o/a Atendente:</option>
                                    {listaAtendentes && listaAtendentes.map((atendente) => {
                                        const selected = atendimentoEditar && atendimentoEditar.atendente === parseInt(atendente.id);
                                        return <option key={atendente.id} selected={selected} value={atendente.id}>{atendente.nome}</option>
                                    }
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        :
                        <></>
                    }
                    <Col>
                        <Form.Group as={Col} controlId="formAtendente">
                            <Form.Label>Tags{(!isMobile && " do Atendimento") || ""}:</Form.Label>
                            <CreatableSelect
                                isMulti
                                options={listaTags}
                                formatCreateLabel={() => `Cadastrar`}
                                className="basic-multi-select"
                                menuPlacement='top'
                                name="tagsAtendimento"
                                placeholder="Selecionar ou  criar"
                                onChange={(event) =>  handleTagsSelecao(event)}
                                value={selectedTags}
                                maxMenuHeight= {190}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>
        {
          (!enviando) && <button type="button" className="btn btn-block btn-primary btnNovoEditar" onClick={handleSubmitForm}>{textButton}</button>
        }
        </Form>

    </div>
}
