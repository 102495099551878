import React from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MidiaSocialIcone from "../MidiaSocialIcone";
import {IconePrioridade} from "../IconePrioridade";
import moment from "moment";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { cpfMask } from "../InputTextMask";
import caAPI from "../../services/caAPI";

export function CardChamado({chamado, handleVincularChamado=null, handleDesvincularChamado=null}){
  
  const resolveBadgeStatus = (descricao, cor)=>{
    return (
      <OverlayTrigger 
         placement="top"
         overlay={<Tooltip>{descricao}</Tooltip>}
      >
        <span className="badge-status" style={{backgroundColor: cor, color: "#fff"}}>{descricao}</span>
      </OverlayTrigger>
    )
  }

  const handleOpenChamado = ()=>{
    caAPI.buscarTokenAcessoModulo(caAPI.ID_MODULO_DESK).then(response => {
      window.open(`${response?.data?.modulo?.uri}${response?.data?.token}:?idChamado=${btoa(chamado?.id_ticket?.toString())}`, '_blank')
    })
  }

  return (
    <div className="card-chamado-section">
      <div className="dados-section">
        <div className="first-column">
          <span className="protocolo" onClick={()=>{handleOpenChamado()}}>{chamado.protocolo}</span>
          <span className="data">{moment(chamado.criado_em).format('DD/MM/Y')}</span>
          <MidiaSocialIcone midia={chamado?.origem_ticket ?? ""}/>
          <IconePrioridade prioridade={chamado?.prioridade?.nome}/>
        </div>
        <div className="second-column">
          <div className="info-section">
            <label>Categoria/Motivo</label>
            <span>{chamado?.categoria?.nome}/{chamado?.motivo?.nome}</span>
          </div>
          <div className="info-section">
            <label>Assunto</label>
            <span>{chamado?.assunto}</span>
          </div>
          <div className="info-section">
            <label>Solicitante/Responsável</label>
            <span className="label-responsaveis"><FontAwesomeIcon icon={faArrowRight} color="red"/> {chamado?.solicitante?.nome ?? chamado?.solicitante ?? "Não Encontrado"}</span>
            <OverlayTrigger 
              placement="top"
              overlay={<Tooltip>{chamado.responsaveis_execucao_nomes}</Tooltip>}
            >
              <span className="label-responsaveis"><FontAwesomeIcon icon={faArrowLeft} color="green"/> {chamado?.responsaveis_execucao_nomes}</span>
            </OverlayTrigger>
          </div>
          {chamado.id_processo ? 
            <div className="info-section">
              <label>Processo</label>
              <span>{chamado?.id_processo.toString().padStart(5, '0')}</span>
            </div>
            :
            chamado.dados_empreendimento ?
              <div className="dados-empreendimento-section">
                <div className="info-section">
                  <label>Proponente:</label>
                  <span>{chamado?.dados_empreendimento?.nome_proponente ?? ""}</span>
                </div>
                <div className="info-section">
                  <label>Documento:</label>
                  <span>{cpfMask(chamado?.dados_empreendimento?.cpf_cnpj_proponente ?? "")}</span>
                </div>
                {chamado?.dados_empreendimento?.nome_empreendimento && 
                  <div className="info-section">
                    <label>Empreendimento:</label>
                    <span>{chamado?.dados_empreendimento?.nome_empreendimento ?? ""} | {chamado?.dados_empreendimento?.unidade_descricao ?? ""}</span>
                  </div>
                }
              </div>
            : <></>
          }
        </div>
      </div>
      <div className="footer-section">
        {handleVincularChamado && 
          <Button onClick={() => handleVincularChamado(chamado)} className="btn-vincular-atendimento">Vincular ao atendimento</Button>
        }
        {handleDesvincularChamado && 
          <Button onClick={() => handleDesvincularChamado(chamado)} className="btn-desvincular-atendimento">Desvincular</Button>
        }
        <div className="badge-status-section">
          {resolveBadgeStatus(chamado?.status?.nome, chamado?.status?.cor)}
          {chamado?.substatus ? resolveBadgeStatus(chamado?.substatus?.nome, chamado?.substatus?.cor) : <></>}
        </div>
      </div>
    </div>
  )
}
