import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import "./index.scss";
import Select from "react-select";
import ticketService from "../../../../../services/TicketService";
import ticketCategoria from "../../../../../services/TicketCategoriaService";
import ticketMotivo from "../../../../../services/TicketMotivoService";
import ticketStatus from "../../../../../services/TicketStatusService";
import ticketTipo from "../../../../../services/TicketTipoService";
import ticketPrioridade from "../../../../../services/TicketPrioridadeService";
import caAPI from "../../../../../services/caAPI";
import helper from "../../../../../helpers/format";
import moment from "moment";

import { trackPromise } from "react-promise-tracker";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Anexos from "../../../../../components/Anexos";

const dadosIndicado = yup.object({
  assunto: yup
    .string()
    .min(2, "Assunto muito pequeno")
    .required("Campo obrigatório"),
  tipo: yup.string().required("Campo obrigatório"),
  prioridade: yup.string().required("Campo obrigatório"),
  categoria: yup.string().required("Campo obrigatório"),
  motivo: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
});

export default function NovoChamado({ corTop, corButton, numeroOPVProcesso, setRefresh, close, nomeProponente, idProcesso, idAtendimento, numeroAtendimento, dadosProcesso, meioComunicacao }) {
  // const [processoOpv, setProcessoOpv] = useState("");
  // const [processoOpvNome, setProcessoOpvNome] = useState("");
  // const [statusOptions, setStatusOptions] = useState([]);
  const [listaAnexos, setListaAnexos] = useState([])
  const [prioridadesOptions, setPrioridadesOptions] = useState([]);
  const [prioridadePorMotivo, setPrioridadePorMotivo] = useState(false);

  const [motivosOptions, setMotivosOptions] = useState([]);
  const [motivosPorTipo, setMotivosPorTipo] = useState(false);
  const [motivosPorCategoria, setMotivosPorCategoria] = useState(false);

  const [categoriasOptions, setCategoriasOptions] = useState([]);
  const [categorias, setCategorias] = useState(false);

  const [tiposOptions, setTiposOptions] = useState([]);
  // const [listaProcessos, setListaProcessos] = useState([]);
  const [tipo, setTipo] = useState(false);

  // const [tipoAlterado, setTipoAlterado] = useState("");
  // const [categoriaAlterada, setCategoriaAlterada] = useState("");

  const [prazoLimiteSLA, setPrazoLimiteSLA] = useState("");
  const [prazoLimiteSLO, setPrazoLimiteSLO] = useState("");
  const [diasPrazo, setDiasPrazo] = useState("");
  const initialValues = {
    assunto: "",
    tipo: "",
    categoria: "",
    motivo: "",
    prioridade: "",
    processo_opv: "",
    descricao: "",
    prazo_limite: "",
  };

  useEffect(() => {
    trackPromise(
      Promise.all([
        ticketCategoria.index(),
        ticketMotivo.index(),
        ticketStatus.index(),
        ticketTipo.index(),
        ticketPrioridade.index(),
        caAPI.atendimento.email.buscarUltimosAnexosPorAtendimento(idAtendimento || numeroAtendimento)
      ])
        .then((response) => {
          let categoriasOptions = [];
          Object.values(response[0].data).forEach((item) => {
            categoriasOptions.push({
              value: item.id_ticket_categoria,
              label: item.nome,
            });
          });
          setCategoriasOptions(Object.values(response[0].data));

          let motivosOptions = [];
          Object.values(response[1].data).forEach((item) => {
            motivosOptions.push({
              value: item.id_ticket_motivo,
              label: item.nome,
            });
          });
          setMotivosOptions(Object.values(response[1].data));

          // const novoStatusOptions = [];
          // Object.values(response[2].data).forEach((item) => {
          //   novoStatusOptions.push({
          //     value: item.id_ticket_status,
          //     label: item.nome,
          //     sla: item.sla,
          //     slo: item.slo,
          //   });
          // });
          // setStatusOptions(novoStatusOptions);

          let tiposOptions = [];
          Object.values(response[3].data).forEach((item) => {
            tiposOptions.push({
              value: item.id_ticket_tipo,
              label: item.nome,
            });
          });
          setTiposOptions(Object.values(response[3].data));
          let prioridadesOptions = [];
          Object.values(response[4].data).forEach((item) => {
            prioridadesOptions.push({
              value: item.id_ticket_prioridade,
              label: item.nome,
            });
          });
          setPrioridadesOptions(Object.values(response[4].data));
          setListaAnexos(response[5].data)
        })
        .catch((error) =>
          swal(
            "Ooops...",
            "Ocorreu um erro ao buscar as dados: " + error,
            "error"
          )
        )
    );
  }, []);
  function cadastraTicket(values) {
    let dados = {
      assunto: values.assunto,
      tipo_cadastro: "O",
      descricao: values.descricao,
      id_processo: idProcesso ? idProcesso : numeroOPVProcesso,
      id_usuario_solicitante: caAPI.getLocalState("idUsuario"),
      id_ticket_status: 1,
      id_ticket_categoria: values.categoria,
      id_ticket_prioridade: values.prioridade,
      id_ticket_tipo: values.tipo,
      id_ticket_motivo: values.motivo,
      prazo_sla: helper.formataMysqlDateHour(prazoLimiteSLA),
      prazo_slo: helper.formataMysqlDateHour(prazoLimiteSLO),
      id_atendimento: idAtendimento ? idAtendimento : numeroAtendimento,
      origem_ticket: meioComunicacao
    };
    const formData = new FormData()
    for(let [chave, valor] of Object.entries(dados)) {
      formData.append(chave, valor)
    }
    if(listaAnexos?.length > 0) {
      listaAnexos.forEach(arquivo => {
        if(arquivo.nome_arquivo_servidor) {
          formData.append('dados_arquivos_pre_carregados[]', JSON.stringify(arquivo))
        } else {
          formData.append('dados_arquivo[]', arquivo)
        }
      })
    }
    trackPromise(
      ticketService.store(formData).then(
        (response) => {
          const protocolo = response.data.protocolo.split("-");
          Swal.fire({
            titleText: "Chamado aberto",
            text: `Seu chamado foi aberto com sucesso! N° Protocolo ${protocolo[0] + "-" + helper.zeroPad(protocolo[1], 5)
              } e Prazo Limite ${prazoLimiteSLA}`,
            icon: "success",
          });
          setRefresh(true);
          close();
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o chamado: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  // function buscaProcessoOpv() {
  //   if (processoOpvNome !== "") {
  //     trackPromise(
  //       processoService.list(processoOpvNome).then(
  //         (response) => {
  //           setListaProcessos(response.data);
  //         },
  //         (error) => { }
  //       )
  //     );
  //   }
  // }

  function buscaCategoria(value) {
    const categoriaPorTipo = [];
    if (value) {
      categoriasOptions.forEach((categoria) => {
        if (categoria.id_ticket_categoria === value) {
          const item = {
            id_ticket_categoria: categoria.id_ticket_categoria,
            nome: categoria.nome,
          };
          categoriaPorTipo.push(item);
        }
      });
      setCategorias(categoriaPorTipo);
    }
  }

  function buscaMotivo(value) {
    const motivoPorTipo = [];

    if (value) {
      motivosOptions.forEach((motivo) => {
        if (motivo.id_ticket_tipo === value) {
          const item = {
            id_ticket_tipo: motivo.id_ticket_tipo,
            id_ticket_categoria: motivo.id_ticket_categoria,
            id_ticket_prioridade: motivo.id_ticket_prioridade,
            id_ticket_motivo: motivo.id_ticket_motivo,
            nome: motivo.nome,
            sla: motivo.sla,
            slo: motivo.slo,
          };
          motivoPorTipo.push(item);
        }
      });
      setMotivosPorTipo(motivoPorTipo);
    }
  }

  function buscaMotivoPorCategoria(value) {
    let motivoPorCategoria = [];

    if (value) {
      motivosOptions.forEach((motivo) => {
        if (motivo.id_ticket_categoria === value) {
          const item = {
            id_ticket_tipo: motivo.id_ticket_tipo,
            id_ticket_categoria: motivo.id_ticket_categoria,
            id_ticket_prioridade: motivo.id_ticket_prioridade,
            id_ticket_motivo: motivo.id_ticket_motivo,
            nome: motivo.nome,
            sla: motivo.sla_minutos,
            slo: motivo.slo,
          };
          motivoPorCategoria.push(item);
        }
      });
      setMotivosPorCategoria(motivoPorCategoria);
    }
  }
  function buscaPrioridade(value) {
    const prioridadePorMotivo = [];
    if (value) {
      prioridadesOptions.forEach((prioridade) => {
        if (prioridade.id_ticket_prioridade === value) {
          const item = {
            id_ticket_prioridade: prioridade.id_ticket_prioridade,
            nome: prioridade.nome,
          };
          prioridadePorMotivo.push(item);
        }
      });
      setPrioridadePorMotivo(prioridadePorMotivo);
    }
  }
  function buscaTipo(value) {
    const tipoPorMotivo = [];

    if (value) {
      tiposOptions.forEach((tipo) => {
        if (tipo.id_ticket_tipo === value) {
          const item = {
            id_ticket_tipo: tipo.id_ticket_tipo,
            nome: tipo.nome,
          };
          tipoPorMotivo.push(item);
        }
      });
      setTipo(tipoPorMotivo);
    }
  }

  function calculaPrazo(sla, slo) {
    let slaEmDias = Math.floor(sla / 60 / 24);
    let prazoLimite = moment(new Date())
      .add(Number(sla), "minutes")
      .format("DD/MM/YYYY HH:mm");
    setPrazoLimiteSLA(prazoLimite);

    let prazoLimiteSLO = moment(new Date())
      .add(Number(slo), "minutes")
      .format("DD/MM/YYYY HH:mm");
    setPrazoLimiteSLO(prazoLimiteSLO);

    setDiasPrazo(slaEmDias);
  }

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     buscaProcessoOpv();
  //   }
  // };

  return (
    <div className="containerNovoChamado">
      <div className="tituloNovoChamado" style={{ backgroundColor: corTop }}>
        <h5>Novo Chamado | Processo: {numeroOPVProcesso} - {nomeProponente} - {dadosProcesso?.produto?.empreendimento_nome} ({dadosProcesso?.produto?.unidade_descricao})</h5>
      </div>
      <Formik
        validationSchema={dadosIndicado}
        onSubmit={async (values) => {
          cadastraTicket(values);
        }}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Form className="novoChamadoForm">
            {/* <Row>
              <Col>
                <label>
                  <div className="inputLabel">Buscar Processo</div>
                </label>
                <InputGroup className="mb-3">
                  <Field
                    onKeyDown={handleKeyDown}
                    id="Processo"
                    className="form-control"
                    type="text"
                    placeholder="Pesquisar por nome"
                    name="processo_opv"
                    onChange={(event) => (
                      setProcessoOpvNome(event.target.value),
                      handleChange(event)
                    )}
                    validate={touched.processo_opv && !errors.processo_opv}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={() => buscaProcessoOpv()}
                      className="botaoCadastroProcesso"
                    >
                      <Icon
                        icon={searchOutlined}
                        color="var(--dommus-color)"
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                {errors.processo_opv && touched.processo_opv ? (
                  <div className="fildError">{errors.processo_opv}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                {listaProcessos.length > 0 ? (
                  <select
                    name="select"
                    className="form-control"
                    value={processoOpv}
                    onChange={(event) => {
                      setProcessoOpv(event.target.value);
                      handleChange({
                        target: {
                          name: "processo_opv",
                          value: event.target.value,
                        },
                      });
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "processo_opv" } });
                    }}
                  >
                    <option>Selecione um processo</option>
                    {listaProcessos.map((cliente) => (
                      <option key={cliente.id} value={cliente.id}>
                        {helper.zeroPad(cliente.id, 7)} - {cliente.proponente}
                      </option>
                    ))}
                  </select>
                ) : (
                  <></>
                )}
              </Col>
            </Row> */}
            <label>
              <div className="inputLabel">Assunto:</div>
            </label>
            <Field
              className="form-control"
              type="text"
              name="assunto"
              onChange={handleChange}
            />
            {errors.assunto && touched.assunto ? (
              <div className="fildError">{errors.assunto}</div>
            ) : null}
            <Row>
              <Col>
                <label>
                  <div className="inputLabel">Tipo</div>
                </label>
                <Select
                  placeholder="Selecionar"
                  value={
                    tipo
                      ? tipo.map((item) => {
                        return {
                          value: item.id_ticket_tipo,
                          label: item.nome,
                        };
                      })
                      : values.value
                  }
                  name="tipo"
                  onChange={(selectedOption) => {
                    let event = {
                      target: { name: "tipo", value: selectedOption.value },
                    };
                    handleChange(event);
                    buscaMotivo(selectedOption.value);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "tipo" } });
                  }}
                  isSearchable={true}
                  options={
                    tipo
                      ? tipo.map((item) => {
                        return {
                          value: item.id_ticket_tipo,
                          label: item.nome,
                        };
                      })
                      : tiposOptions.map((item) => {
                        return {
                          value: item.id_ticket_tipo,
                          label: item.nome,
                        };
                      })
                  }
                  isLoading={false}
                  loadingMessage={() => "Procurando"}
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                />
                {errors.tipo && touched.tipo ? (
                  <div className="fildError">{errors.tipo}</div>
                ) : null}
              </Col>
              <Col>
                <label>
                  <div className="inputLabel">Categoria</div>
                </label>
                <Select
                  placeholder="Selecionar"
                  value={
                    categorias
                      ? categorias.map((item) => {
                        return {
                          value: item.id_ticket_categoria,
                          label: item.nome,
                        };
                      })
                      : values.value
                  }
                  onChange={(selectedOption) => {
                    let event = {
                      target: {
                        name: "categoria",
                        value: selectedOption.value,
                      },
                    };
                    handleChange(event);
                    buscaMotivoPorCategoria(selectedOption.value);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "categoria" } });
                  }}
                  isSearchable={true}
                  options={
                    motivosPorCategoria
                      ? motivosPorCategoria.map((item) => {
                        return {
                          value: item.id_ticket_categoria,
                          label: item.nome,
                        };
                      })
                      : categoriasOptions.map((item) => {
                        return {
                          value: item.id_ticket_categoria,
                          label: item.nome,
                        };
                      })
                  }
                  name="categoria"
                  isLoading={false}
                  loadingMessage={() => "Procurando"}
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                />
                {errors.categoria && touched.categoria ? (
                  <div className="fildError">{errors.categoria}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <label>
                  <div className="inputLabel">Motivo</div>
                </label>
                <Select
                  placeholder="Selecionar"
                  value={values.value}
                  onChange={(selectedOption) => {
                    let eventTipo = [
                      { target: { name: "tipo", value: selectedOption.tipo } },
                      {
                        target: { name: "motivo", value: selectedOption.value },
                      },
                      {
                        target: {
                          name: "categoria",
                          value: selectedOption.categoria,
                        },
                      },
                      {
                        target: {
                          name: "prioridade",
                          value: selectedOption.prioridade,
                        },
                      },
                    ];
                    eventTipo.forEach((item) => {
                      handleChange(item);
                    });
                    buscaTipo(selectedOption.tipo);
                    buscaCategoria(selectedOption.categoria);
                    buscaPrioridade(selectedOption.prioridade);
                    calculaPrazo(selectedOption.sla_minutos, selectedOption.slo_minutos);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "motivo" } });
                  }}
                  isSearchable={true}
                  options={
                    motivosPorCategoria
                      ? motivosPorCategoria.map((item) => {
                        return {
                          tipo: item.id_ticket_tipo,
                          categoria: item.id_ticket_categoria,
                          prioridade: item.id_ticket_prioridade,
                          value: item.id_ticket_motivo,
                          label: item.nome,
                          sla: item.sla,
                          slo: item.slo,
                          sla_minutos: item.sla_minutos,
                          slo_minutos: item.slo_minutos
                        };
                      })
                      : motivosPorTipo
                        ? motivosPorTipo.map((item) => {
                          return {
                            tipo: item.id_ticket_tipo,
                            categoria: item.id_ticket_categoria,
                            prioridade: item.id_ticket_prioridade,
                            value: item.id_ticket_motivo,
                            label: item.nome,
                            sla: item.sla,
                            slo: item.slo,
                            sla_minutos: item.sla_minutos,
                            slo_minutos: item.slo_minutos
                          };
                        })
                        : motivosOptions.map((item) => {
                          return {
                            tipo: item.id_ticket_tipo,
                            categoria: item.id_ticket_categoria,
                            prioridade: item.id_ticket_prioridade,
                            value: item.id_ticket_motivo,
                            label: item.nome,
                            sla: item.sla,
                            slo: item.slo,
                            sla_minutos: item.sla_minutos,
                            slo_minutos: item.slo_minutos
                          };
                        })
                  }
                  name="motivo"
                  isLoading={false}
                  loadingMessage={() => "Procurando"}
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                />
                {errors.motivo && touched.motivo ? (
                  <div className="fildError">{errors.motivo}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <label>
                  <div className="inputLabel">Prioridade</div>
                </label>
                <Select
                  placeholder="Selecionar"
                  value={
                    prioridadePorMotivo
                      ? prioridadePorMotivo.map((item) => {
                        return {
                          value: item.id_ticket_categoria,
                          label: item.nome,
                        };
                      })
                      : values.value
                  }
                  onChange={(selectedOption) => {
                    let event = {
                      target: {
                        name: "prioridade",
                        value: selectedOption.value,
                      },
                    };
                    handleChange(event);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "prioridade" } });
                  }}
                  isSearchable={true}
                  options={
                    prioridadePorMotivo
                      ? prioridadePorMotivo.map((item) => {
                        return {
                          value: item.id_ticket_prioridade,
                          label: item.nome,
                        };
                      })
                      : prioridadesOptions.map((item) => {
                        return {
                          value: item.id_ticket_prioridade,
                          label: item.nome,
                        };
                      })
                  }
                  name="prioridade"
                  isLoading={false}
                  loadingMessage={() => "Procurando"}
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                />
                {errors.prioridade && touched.prioridade ? (
                  <div className="fildError">{errors.prioridade}</div>
                ) : null}
              </Col>
              <Col>
                <label>
                  <div className="inputLabel">Prazo limite:</div>
                </label>
                <Field
                  className="form-control"
                  type="text"
                  name="prazo_limite"
                  onChange={handleChange}
                  disabled
                  value={
                    diasPrazo ? prazoLimiteSLA + ` (${diasPrazo} Dias)` : ``
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>
                  <div className="inputLabel">Descrição</div>
                </label>
                <Field
                  className="form-control"
                  component="textarea"
                  name="descricao"
                  onChange={handleChange}
                  validate={touched.descricao && !errors.descricao}
                />
                {errors.descricao && touched.descricao ? (
                  <div className="fildError">{errors.descricao}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginBottom: 10 }}>
                <label>
                  <div className="inputLabel"></div>
                </label>
                <div className="av-item select-file">
                  <div className="documentacao-item-wrapper">
                    <div className="form-group">
                      <div>
                        <h6>Arquivos:</h6>
                      </div>
                      <div className="upload-wrapper">
                        <div id="documento-0" className="documento-wrapper">
                          <div className="ajax-upload-dragdrop">
                            <span>
                              <Anexos listaAnexos={listaAnexos} setListaAnexos={setListaAnexos} filesTypes={'*'} formData={true} habilitarPreVisualizacaoImagem={false}/>
                            </span>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="btNovoCadastro"
                  type="submit"
                  style={{ backgroundColor: corButton }}
                >
                  Abrir Chamado
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
