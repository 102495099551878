import React, {useCallback, useEffect, useState} from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import './index.css'
import { PopoverAtendenteDashboard } from "../PopoverAtendenteDashboard";
import imgPadraoUsuario from '../../assets/usuario.svg'
import DommusLoadingWrapped from "../DommusLoading/DommusLoadingWrapped";
import { DashboardService } from "../../services/DashboardService"
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

export function CartaoDashboardAtendente({ usuario }) {

  const [metricas, setMetricas] = useState(null);
  const [timeoutAtualizar, setTimeoutAtualizar] = useState(null);

  const atualizarMetricas = () => {
    if(timeoutAtualizar){
      clearTimeout(timeoutAtualizar);
      setTimeoutAtualizar(null);
    }
    trackPromise(
      DashboardService.buscarMetricasUsuario(usuario.id_usuario),
      "metricasUsuario" + usuario?.id_usuario
    ).then(res => {
        setMetricas(res.data)
        const novoTimeout = setTimeout(() => {
          atualizarMetricas();
        }, 60000);
        setTimeoutAtualizar(novoTimeout);
      }).catch(error => {
        const novoTimeout = setTimeout(() => {
          atualizarMetricas();
        }, 60000);
        setTimeoutAtualizar(novoTimeout);
        Swal.fire('Ooops...', `Houve um erro ao buscar os usuários. Erro: ${error}, Detalhes: ${error.response?.data}`, 'error')
      })
  };

  useEffect(() => {
    if(usuario && usuario.id_usuario){
      atualizarMetricas();
    }
  }, [usuario]);


  return (<Card as={Col} className="px-0 shadow card-dashboard">
    <Image 
      id="img-usuario" 
      className={`rounded-circle align-self-center mt-3 ${usuario.estaOnline ? 'border-success bg-success' : 'border-danger bg-danger'}`} 
      width={150} 
      height={150}
      src={usuario.foto_perfil ?? imgPadraoUsuario}
      data-tip={`${usuario.nome} está ${usuario.estaOnline ? 'online' : 'offline'}`}
    />
    <h4 className="text-center pt-2 pb-1">{`${usuario.nome}`}</h4>
    <Card.Body className="pb-2 pt-0">
      <DommusLoadingWrapped area={("metricasUsuario" + usuario?.id_usuario)} fundo={"#fff"}>
      {
        metricas && 
        <>
          <Row className="mb-2">
            <Col className="cursor-help px-0 mx-1 border rounded" data-tip={`${usuario.tempoOnline.split('h')[0]} horas e ${usuario.tempoOnline.split('h')[1].slice(0, -1)} minutos online`}>
              <Form.Text className="text-center font-16-px-hover"><strong>{usuario.tempoOnline}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Tempo Online</Form.Text>
            </Col>
            <Col className="cursor-help bg-secondary text-light rounded px-0 mx-1" data-tip={`${metricas.quantidadeAtendimentos} atendimentos recebidos`}>
              <Form.Text className="text-center font-16-px"><strong>{metricas.quantidadeAtendimentos}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Atendimentos</Form.Text>
            </Col>
            <Col 
              className="cursor-help px-0 mx-1 bg-coluna-3-dashboard rounded" 
              data-tip={`${metricas.quantidadeLigacoesNaoAtendidas} ligações não atendidas, ${Number(metricas.quantidadeLigacoes) - Number(metricas.quantidadeLigacoesNaoAtendidas)} atendidas e ${metricas.quantidadeLigacoes} no total`}
            >
              <Form.Text className="text-center font-16-px"><strong>{metricas.quantidadeLigacoes}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Voip</Form.Text>
            </Col>
            <Col  
              className="cursor-help px-0 mx-1 border rounded" 
              data-tip={`${metricas.quantidadeLigacoesRecebidas} ligações recebidas e ${metricas.quantidadeLigacoesRealizadas} ligações realizadas`}
            >
              <Form.Text className="text-center font-16-px-hover">
                <strong>{`${metricas.quantidadeLigacoesRecebidas} | ${metricas.quantidadeLigacoesRealizadas}`}</strong>
              </Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Ligações Recebidas | Realizadas</Form.Text>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col 
              className="cursor-help px-0 mx-1 border rounded" 
              data-tip={`${metricas.minutagemVoip.split('h')[0]} horas e ${metricas.minutagemVoip.split('h')[1].slice(0, -1)} minutos de ligação`}
            >
              <Form.Text className="text-center font-16-px-hover"><strong>{metricas.minutagemVoip}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Minutagem Voip</Form.Text>
            </Col>
            <Col 
              className={`cursor-help px-0 mx-1 border rounded ${Number(metricas.quantidadeProdutividadePorcentual.slice(0, -1)) > 50 ? 'bg-success' : 'bg-danger'}`} 
              data-tip={`${metricas.quantidadeProdutividade} atendimentos realizados (${metricas.quantidadeProdutividadePorcentual})`}
            >
              <Form.Text className="text-center font-16-px text-light"><strong>{`${metricas.quantidadeProdutividade} | ${metricas.quantidadeProdutividadePorcentual}`}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard text-light">Produtividade</Form.Text>
            </Col>
            <Col 
              className="cursor-help px-0 mx-1 border rounded bg-coluna-3-dashboard rounded" 
              data-tip={`${metricas.quantidadeAtendimentosWhatsappNaoLidos} conversas não lidas ou respondidas de um total de ${metricas.quantidadeAtendimentosWhatsapp} conversas`}
            >
              <Form.Text className="text-center font-16-px">
                <strong>{`${metricas.quantidadeAtendimentosWhatsappNaoLidos}* | ${metricas.quantidadeAtendimentosWhatsapp}`}</strong>
              </Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Whatsapp</Form.Text>
            </Col>
            <Col className="cursor-help px-0 mx-1 border rounded" data-tip={`${metricas.quantidadeAtendimentosResgate} atendimentos resgatados da sala de espera`}>
              <Form.Text className="text-center font-16-px-hover"><strong>{metricas.quantidadeAtendimentosResgate}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Resgates Sala Espera</Form.Text>
            </Col>
          </Row>
          <Row>
            <Col 
              className="cursor-help px-0 mx-1 border rounded" 
              data-tip={`${metricas.tempoMedioLigacao.split('m')[0]} horas e ${metricas.tempoMedioLigacao.split('s')[1].slice(0, -1)} minutos de ligação`}
            >
              <Form.Text className="text-center font-16-px-hover"><strong>{metricas.tempoMedioLigacao}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">TM Ligação</Form.Text>
            </Col>
            <Col 
              className="cursor-help px-0 mx-1 border rounded" 
              data-tip={`${metricas.quantidadeAtendimentosFinalizados} atendimentos concluídos (${metricas.quantidadeAtendimentosFinalizadosPorcentual})`}
            >
              <Form.Text className="text-center font-16-px-hover">
                <strong>{`${metricas.quantidadeAtendimentosFinalizados} | ${metricas.quantidadeAtendimentosFinalizadosPorcentual}`}</strong>
              </Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Concluídos</Form.Text>
            </Col>
            <Col 
              className="cursor-help px-0 mx-1 border rounded bg-coluna-3-dashboard rounded" 
              data-tip={`${metricas.quantidadeAtendimentosEmailNaoLidos} emails não lidos de um total de ${metricas.quantidadeAtendimentosEmail} emails`}>
              <Form.Text className="text-center font-16-px"><strong>{`${metricas.quantidadeAtendimentosEmailNaoLidos}* | ${metricas.quantidadeAtendimentosEmail}`}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">E-mails</Form.Text>
            </Col>
            <Col className="cursor-help px-0 mx-1 border rounded" data-tip={`${metricas.quantidadeTicketsAbertos} tickets abertos`}>
              <Form.Text className="text-center font-16-px-hover"><strong>{metricas.quantidadeTicketsAbertos}</strong></Form.Text>
              <Form.Text className="text-center label-descricao-campo-dashboard">Tickets abertos</Form.Text>
            </Col>
          </Row>
        </>
      }
      </DommusLoadingWrapped>
    </Card.Body>
    <Card.Footer className="py-2 px-2">
      {
        metricas &&
        <>
          <small className="text-muted">*<em>Ligações não atendidas ou Mensagens não lidas</em></small>
          <PopoverAtendenteDashboard idUsuario={usuario.id_usuario}/>
        </>
      }
      
    </Card.Footer>
  </Card>)
}