import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import DommusLoading from "./DommusLoading";

export default function DommusLoadingWrapped({area, fundo = "rgba(0,0,0,.5)", children}) {
	const {promiseInProgress} = usePromiseTracker({area: area});
	return (
		(promiseInProgress &&
		<DommusLoading loadWrapperPosition={"relative"} fundo={fundo}/>) || children
	);
}
