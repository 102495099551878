import React, { useState, useContext, useEffect, useCallback } from 'react';
import DadosDetalhes from './DadosDetalhes'
import BuscarProcesso from "./BuscarProcesso";
import { trackPromise } from "react-promise-tracker";
import caAPI from "../../../services/caAPI";

import { successToast, errorToast } from "../../../components/DommusToast";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import DetalhesOpv from './DetalhesOpv';
import { ModalDadosVinculacao } from './ModalDadosVinculacao';
import DommusLoadingArea from '../../../components/DommusLoading/DommusLoadingArea';
import { DetalhesChamados } from './DetalhesChamados';
import Swal from 'sweetalert2';

export default function DetalheProcesso() {

  const [dadosClienteProcesso, setDadosClienteProcesso] = useState(null);
  const [dadosClienteOpv, setDadosClienteOpv] = useState(null);
  const [dadosClienteTicket, setDadosClienteTicket] = useState(null);
  const [listaProcessos, setListaProcessos] = useState([]);
  const [titleToggle, setTitleToggle] = useState(null);
  const { handleAdiconaLista, atendimentoAtivo, setAtendimentoAtivo } = useContext(AtendimentoContext);
  const [loading, setLoading] = useState(false);
  const [idProcesso, setIdProcesso] = useState(null);
  const [idOpv, setIdOpv] = useState(null);
  const [idAtendimento, setIdAtendimento] = useState(null);
  const [tipoDetalhes, setTipoDetalhes] = useState(null);
  const [openModalVinculacao, setOpenModalVinculacao] = useState(false);
  const [processoSelecionado, setProcessoSelecionado] = useState({});


  const renderTitleToggle = (type, number) => {
    setTipoDetalhes(type);
    let title;
    if (!number) return;
    if (type === 'processo') {
      number ? title = 'Processo: ' + number.toString().padStart(5, '0') : title = 'Processo:';
    }else if (type === 'oportunidade') {
      title = 'OPV-' + number.toString().padStart(5, '0');
    }else if (type === 'chamado'){
      title = 'Chamados';
    }
    setTitleToggle(title);
  }

  const handleSetClienteDadosOpv = useCallback(function (dados) {
    setDadosClienteOpv(dados)
  }, [setDadosClienteOpv]);

  const handleSetClienteDadosProcesso = useCallback(function (dados) {
    setDadosClienteProcesso(dados)
  }, [setDadosClienteProcesso]);

  const buscarDetalhes = useCallback(function (id, tipo) {
    setDadosClienteProcesso(null);
    setDadosClienteOpv(null);
    if (tipo === "processo") {
      trackPromise(
        caAPI.atendimento.processo.buscaPorId(id).then(res => {
          handleSetClienteDadosProcesso(res.data)
        }
        ).catch(err =>
         setLoading(false)
        ), 'loading-detalhes-processo');
    } else if (tipo === "oportunidade") {
      trackPromise(
        caAPI.atendimento.opv.buscaPorId(id).then(res => {
          handleSetClienteDadosOpv(res.data)
        }
        ).catch(err =>
          setLoading(false)
        ), 'loading-detalhes-processo');
    } else if(tipo === "chamado"){
      trackPromise(
        caAPI.atendimento.buscarChamadosVinculados(id).then(response=>{
          setDadosClienteTicket(response.data);
          renderTitleToggle('chamado', 1);
        })
      )
    }

  }, [handleSetClienteDadosProcesso, handleSetClienteDadosOpv]);

  useEffect(() => {
    if (atendimentoAtivo && (atendimentoAtivo.processo || atendimentoAtivo.oportunidade)) {
      renderTitleToggle('processo', atendimentoAtivo.processo);
      if (atendimentoAtivo.processo) {
        buscarDetalhes(atendimentoAtivo.processo, "processo");
        return;
      }
      renderTitleToggle('oportunidade', atendimentoAtivo.oportunidade);

      if( atendimentoAtivo.oportunidade !== null 
        && atendimentoAtivo.oportunidade !== undefined){
          buscarDetalhes(atendimentoAtivo.oportunidade, "oportunidade")
        }
         
    } else {
      setDadosClienteProcesso(null);
      setDadosClienteOpv(null);
      buscarPorDados(atendimentoAtivo.contato_destino, false)
    }
    buscarDetalhes(atendimentoAtivo.id, "chamado");

    return ()=>{
      setDadosClienteProcesso(null);
      setDadosClienteOpv(null);
    }

  }, [atendimentoAtivo, buscarDetalhes, setDadosClienteProcesso])

  const buscarDados = function (info, tipo) {
    switch(tipo){
      case 0:
        buscarPorDados(info);
        break;
      case 1:
        buscarPorOpv(info);
        break;
      case 2:
        buscarPorProcesso(info);
        break;
      case 3:
        buscarPorChamado(info);
        break;
      default:
        break;
    }
  }

  function buscarPorDados(info, showError=true){
    setLoading(true)
    setListaProcessos([]);
    trackPromise(
      caAPI.atendimento.processo.buscarPorInfo(info).then(res => {
        setLoading(false)
        setListaProcessos(res.data)
      }
      ).catch(err => {
        setLoading(false)
       
      }
      )
      , "buscarProcessoArea")
  }

  function buscarPorProcesso(info, showError=true){
    setLoading(true);
    trackPromise(
      caAPI.atendimento.processo.buscaPorId(info).then(res => {
        setLoading(false);
        let dados = {
          processo: info,
          oportunidade: null,
          proponentes:
            res.data.proponentes.map((proponente)=>{
              return {
                nome: proponente.nome,
                cpf: proponente.cpf,
                email: proponente.email,
                telefone: proponente.telefone,
              }
            }),
          empreendimento:{
            nome: res.data.produto.empreendimento_nome,
            unidade: res.data.produto.unidade_descricao ? res.data.produto.unidade_descricao : "---",
          },
          atendimento:{
            nome: atendimentoAtivo.nome,
            contato: atendimentoAtivo.contato_destino,
            situacao: atendimentoAtivo.situacao_atendimento.nome,
            meioComunicacao: atendimentoAtivo.meio_comunicacao.slug,
          },
        }
        setProcessoSelecionado(dados);
        setOpenModalVinculacao(true);
      }
      ).catch(err => {
        
        setLoading(false)
      }
      )
      , "buscarProcessoArea")
  }

  function buscarPorChamado(info, naoPossuiProcesso=false){
    setLoading(true);
    trackPromise(
      caAPI.atendimento.chamado.buscarPorId(info).then(response => {
        setLoading(false);

        if(naoPossuiProcesso){
          if(response.data.id_processo){
            Swal.fire(
              'Ooops ...',
              'O chamado escolhido possui um processo vinculado. Para vincular a esse chamado é necessário desvincular os chamados vinculados!',
              'error'
            );
            return;
          }
        }

        let proponentes = [];
        if(response?.data?.proponentes){
          proponentes = Object.keys(response?.data?.proponentes).map((item)=>{
            return response?.data?.proponentes[item];
          })
        }

        let dados = {
          processo: response?.data?.id_processo ?? null,
          oportunidade: null,
          ticket: response?.data?.id_ticket,
          proponentes: proponentes.length ? 
            proponentes.map((proponente)=>{
              return {
                nome: proponente.nome,
                cpf: proponente.cpf,
                email: proponente.email,
                telefone: proponente.telefone,
              }
            }) : [{
              nome: response?.data?.dados_empreendimento?.nome_proponente ?? "",
              cpf: response?.data?.dados_empreendimento?.cpf_cnpj_proponente ?? "",
              email: response?.data?.dados_empreendimento?.email_proponente ?? "",
              telefone: response?.data?.dados_empreendimento?.telefone_proponente ?? "",
            }],
          empreendimento:{
            nome: response.data.dados_empreendimento?.nome_empreendimento,
            unidade: response.data.dados_empreendimento?.descricao_unidade,
          },
          ticket_dados:response?.data,
          atendimento:{
            nome: atendimentoAtivo.nome,
            contato: atendimentoAtivo.contato_destino,
            situacao: atendimentoAtivo.situacao_atendimento.nome,
            meioComunicacao: atendimentoAtivo.meio_comunicacao.slug,
          },
        }
        setProcessoSelecionado(dados); 
        setOpenModalVinculacao(true);
      }
      ).catch(err => {        
        setLoading(false);
        Swal.fire(
          'Ooops ...',
          'Chamado não encontrado',
          'error'
        )
      }), "buscarProcessoArea")
  }

  function buscarPorOpv(info, showError=true){
    setLoading(true);
    trackPromise(
      caAPI.atendimento.opv.buscaPorId(info).then(res => {
        setLoading(false)
        let dados = {
          processo: res.data.id_processo,
          oportunidade: info,
          proponentes:
            res.data.envolvidos.map((proponente)=>{
              return {
                nome: proponente.envolvido.nome,
                cpf: proponente.envolvido.cpf,
                email: proponente.envolvido.email,
                telefone: proponente.envolvido.celular ,
              }
            }),
          empreendimento:{
            nome: res.data.empreendimento.nome,
            unidade: "---",
          },
          atendimento:{
            nome: atendimentoAtivo.nome,
            contato: atendimentoAtivo.contato_destino,
            situacao: atendimentoAtivo.situacao_atendimento.nome,
            meioComunicacao: atendimentoAtivo.meio_comunicacao.slug,
          },
        }
        setProcessoSelecionado(dados);
        setOpenModalVinculacao(!openModalVinculacao);
      }
      ).catch(err => {
        setLoading(false)
      }
      )
      , "buscarProcessoArea")
  }

  function handleSelecionarDados(index){

    let dados = {
      processo: listaProcessos[index]?.processo_id,
      oportunidade: listaProcessos[index]?.id_oportunidade,
      proponentes:[
        {
          nome: listaProcessos[index].proponente || listaProcessos[index].envolvido_nome,
          cpf: listaProcessos[index].proponente_encontrado_cpf,
          email: listaProcessos[index].email,
          telefone: listaProcessos[index].telefone,
        }
      ],
      empreendimento:{
        nome: listaProcessos[index]?.empreendimento_nome,
        unidade: "---",
      },
      atendimento:{
        nome: atendimentoAtivo.nome,
        contato: atendimentoAtivo.contato_destino,
        situacao: atendimentoAtivo.situacao_atendimento.nome,
        meioComunicacao: atendimentoAtivo.meio_comunicacao.slug,
      },
    }
    setProcessoSelecionado(dados);
    setOpenModalVinculacao(true);
  }

  function handleSelecionaProcesso(id_processo, tipo, auxiliar=null) {

    setTipoDetalhes(tipo);
    const data = {
      tipo,
      id_processo,
      auxiliar
    };

    if (id_processo) {
      trackPromise(caAPI.atendimento.processo.salvaProcesso(atendimentoAtivo.id, data).then(data => {
        buscarDetalhes(id_processo, tipo)
        setIdAtendimento(data.data.id)
        if (tipo === "processo") {
          renderTitleToggle('processo', id_processo);
          setIdProcesso(data.data.processo)
          successToast.fire('Processo vinculado com sucesso!');
          const novoAtendimentoAtivo = Object.assign({}, atendimentoAtivo);
          novoAtendimentoAtivo.processo = id_processo;
          setAtendimentoAtivo(novoAtendimentoAtivo);
          handleAdiconaLista(novoAtendimentoAtivo);
        }
        else if (tipo === "oportunidade") {
            renderTitleToggle('oportunidade', id_processo);
            setIdOpv(data.data.oportunidade || 661) //hardcode pq salva processo não retorna opv salva
            successToast.fire('OPV vinculada com sucesso!')
            const novoAtendimentoAtivo = Object.assign({}, atendimentoAtivo);
            novoAtendimentoAtivo.oportunidade = id_processo;
            setAtendimentoAtivo(novoAtendimentoAtivo);
            handleAdiconaLista(novoAtendimentoAtivo);
        }else if(tipo === "chamado") {
          if(data.data.processo){
            renderTitleToggle('processo', data?.data?.processo);
            setIdProcesso(data.data.processo)
            successToast.fire('Processo vinculado com sucesso!');
            const novoAtendimentoAtivo = Object.assign({}, atendimentoAtivo);
            novoAtendimentoAtivo.processo = data?.data?.processo;
            setAtendimentoAtivo(novoAtendimentoAtivo);
            handleAdiconaLista(novoAtendimentoAtivo);
          }else{
            buscarDetalhes(atendimentoAtivo.id, 'chamado');
          }
        }

        setListaProcessos([])
        setOpenModalVinculacao(false);
      }).catch(err =>
        setLoading(false)
      )
      )
    }
  }

  return (
    <>
    <DommusLoadingArea area="loading-detalhes-processo" fundo={"#fff"}/>

      {dadosClienteProcesso &&
        <DadosDetalhes
          tipoDetalhes={tipoDetalhes}
          handleSelecionaProcesso={handleSelecionaProcesso}
          handleSetClienteDadosProcesso={handleSetClienteDadosProcesso}
          titleToggle={titleToggle}
          cliente={dadosClienteProcesso}
          idProcesso={idProcesso}
          idAtendimento={idAtendimento}
        />
      }
      {dadosClienteOpv && dadosClienteOpv.lead &&
        <DetalhesOpv
          handleSelecionaProcesso={handleSelecionaProcesso}
          handleSetClienteDadosOpv={handleSetClienteDadosOpv}
          titleToggle={titleToggle}
          cliente={dadosClienteOpv}
          idOpv={idOpv}
          idAtendimento={idAtendimento}
          />
      }
      {!dadosClienteProcesso && dadosClienteTicket?.length ?
        <DetalhesChamados
          tipoDetalhes={tipoDetalhes}
          titleToggle={titleToggle}
          chamados={dadosClienteTicket}
          idAtendimento={atendimentoAtivo.id}
          setChamados={setDadosClienteTicket}
          handleVincularChamado={buscarPorChamado}
        />
        : <></>
      }
      {!idProcesso && !dadosClienteProcesso && !(dadosClienteOpv && dadosClienteOpv.lead) && !dadosClienteTicket?.length &&
        <BuscarProcesso
        loading={loading} 
          atendimento={atendimentoAtivo}
          buscarProcessos={buscarDados}
          listaProcessos={listaProcessos}
          handleSelecionaProcesso={handleSelecionaProcesso}
          handleSelecionarDado={handleSelecionarDados}
        />
      }
      <ModalDadosVinculacao
        openModal={openModalVinculacao}
        handleModal={setOpenModalVinculacao}
        processoSelecionado={processoSelecionado}
        handleVincularProcesso={handleSelecionaProcesso}
      />
    </>
  )

}
